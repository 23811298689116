.unsbscribe-center{
    text-align: center;
    padding: 1em;
}
.password-form{
    width:256px;
    margin: 0 auto;
}
#password{
    width:100%;
}
.input-right{
    text-align: right;
    margin:1em 0;
}
.input-label{
    text-align: left;
    margin:1em 0;
    display:block;
}
.error-message{
    color: crimson;
}