.import-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 2em;
    width: 264px;
}
.import-canvas{
    width: 100%;
    height: auto;
}
.import-label{
    display: block;
    margin: 0 0 0.7em;
    text-align: left;
    width: 200px;
    font-weight: bold;
}
.import-input{
    margin: 0 0 0.7em;
}
.import-input-name{
    width: 200px;
    margin: 0 0 0.7em;
}
.import-bottom{
    width:200px;
    text-align: right;
    margin: 1em 0;
}
.import-error{
    color:crimson;
    margin:1em 0;
}
.import-select{
    width:200px;
    padding: 0.3em 0;
}

.import-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 200px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin: 1em 0;
  }
.import-swatch{
    background-color: red;
    width: 1.55em;
    height: 1.55em;
}
.import-browse{
    background-color: #555;
    border-radius:0.5em;
    padding:0.5em;
    margin:2em 0;
}
.import-canvas-wrapper{
    width:200px;
    height:200px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.import-submit{
    margin-left: 0.5em;
}
.import-loading-wrapper{
    padding:1em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.import-input-wrapper{
    width:200px;
    text-align: left;
}
.import-size{
    width: 70px;
}