.cookie-consent-footer {
    position: fixed;
    right: 0;
    left:0;
    bottom: 0;
    background-color: #f4f4f4;
    color: #000;
    text-align: center;
    padding: 1em;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.cookie-consent-footer p {
    margin: 0;
    padding-bottom: 10px;
}

.cookie-consent-footer button {
    padding: 5px 10px;
    cursor: pointer;
    color: crimson;
}