.close-button {
    padding: 0.4em;
}
.modal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(64, 64, 64, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapper{
    height: 100%;
    display:flex;
    overflow: hidden;
}
.wrapper, .wrapper * {
    outline: none;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
}
.wrapper canvas{
    image-rendering: crisp-edges;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
}