h4{ 
  margin: 0 0.3em;
}
.tool-wrapper-outer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.tool-wrapper-inner {
  padding: 1em 0;
  width: 100%;
  height: 100%;
  max-width: 200px;
  margin: 0 auto;
  box-sizing: border-box;
  touch-action: auto;
}
.tool-label {
  display: block;
  margin: 0.5em 0.3em;
}
.tool-integer{
  display:inline-block;
  width: 2.2em;
}
.tool-integer1{
  display:inline-block;
  width: 1.7em;
}
.tool-integer-big{
  display:inline-block;
  width: 2.6em;
}
.tool-float{
  display:inline-block;
  width: 4em;
}
.tool-coord{
  display:inline-block;
  width: 4em;
  margin: 0 0.3em;
}
.tool-hex{
  display:inline-block;
  width: 4em;
}
.tool-range {
  width: 80%;
  margin: 0 0.3em;
}
.tool-checkbox{
  margin: 0 0.5em;
  transform: scale(1.25);
}
.brush-preview {
  display: block;
  padding: 8px;
  width: 80%;
  height: auto;
  margin: 0 auto;
  outline: 2px solid #fff;
  box-sizing: border-box;
}
.crop-button{
  background-color: #fff;
  padding:0.3em;
  border-radius: 0.3em;
  color:#000;
  margin: 0 0.3em;
}
.guide-swatch{
  display: inline-block;
  outline: 2px solid #fff;
  width: 1em;
  height: 1em;
  -webkit-box-shadow: 0 0 4px 4px #000;
  -moz-box-shadow: 0 0 4px 4px #000;
  box-shadow: 0 0 4px 4px #000;
  -webkit-appearance: none;
  border-radius: 1px;
  cursor: pointer;
  margin-left: 0.5em;
}