.rename-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 3em;
    width: 200px;
}
.rename-heading{
    font-size:1.4em;
}
.rename-label{
    display: block;
    margin: 0.7em 0;
    text-align: left;
    width: 200px;
}
.rename-input{
    width: 78px;
}
.rename-input-name{
    width: 200px;
}
.rename-bottom{
    width:200px;
    text-align: right;
    margin: 1em 0;
}
.rename-error{
    color:crimson;
    margin:1em 0;
}
.sign-in-button{
    color:limegreen;
}