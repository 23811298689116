.left-panel,
.right-panel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.right-wrapper {
  flex: 0 0 auto;
  display: flex;
  width: 80%;
  max-width: 100%;
  min-width: 1em;
  overflow: hidden;
}
.top-window,
.bottom-window {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.top-window {
  margin-top: 0.5em;
}
.bottom-window {
  flex: 0 0 auto;
  min-height: 1em;
  max-height: 100%;
}
.bottom-window.left {
  height: 50%;
}
.bottom-window.right {
  height: 30%;
}
.bottom-window-content {
  height: 50%;
}
.left-panel .top-window,
.left-panel .bottom-window {
  margin-left: 0.5em;
}

.header-button {
  color: #aaa;
}
.header-button:hover {
  color: #fff;
}
.header-title {
  flex: 0 0 auto;
}

.bottom-divider,
.right-divider {
  flex: 0 0 auto;
  position: relative;
  background-color: #2a2a2a;
  touch-action: none;
}
.bottom-divider {
  cursor: ns-resize;
  height: 1.5em;
}
.right-divider {
  cursor: ew-resize;
  width: 1.5em;
}
.divider-decoration {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #555;
}
.bottom-divider .divider-decoration {
  max-width: 3em;
  width: 100%;
  height: 0.125em;
}
.right-divider .divider-decoration {
  max-height: 3em;
  height: 100%;
  width: 0.125em;
}

@media screen and (max-height: 600px) {
  .bottom-window {
    min-height: 2em;
  }
  .right-wrapper {
    min-width: 2em;
  }
}
@media screen and (max-width: 600px) {
  .bottom-window {
    min-height: 2em;
  }
  .right-wrapper {
    min-width: 2em;
  }
}