.support-nav{
    width: 100%;
}
.support-nav a{
    color:#fff !important;
    padding: 0.5em;
    display: inline-block;
}
.header-logo{
    width:24px;
    height:24px;
}
.support-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
}
.support{
    max-width:420px;
    padding: 1.5em;
    text-align: center;
}