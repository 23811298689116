* {
  box-sizing: content-box;
}
canvas {
  image-rendering: crisp-edges;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}

@viewport {
  width: device-width ;
  zoom: 1.0 ;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



html,
body,
#root,
.app, .content{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2a2a2a;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
}
input {
  font: inherit;
}
button{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.bold{
  font-weight: bold;
}

.grow {
  flex: 1 1;
}

.flex {
  display: flex;
}
.column{
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link, a:visited, a:hover, a:active{
  color:limegreen;
}

@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.icon-spinner {
  font-size:2em;
	animation: anim-rotate 2s infinite linear;
}

.modal{
  width: 100%;
  height: 100%;
  margin:0;
  display:flex !important;
  background: none;
  border: none;
}
.modal-content{
  background:none;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.loading-wrapper{
  position: absolute;
  width:100%;
  height:100%;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}