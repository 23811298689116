.palette-wrapper-outer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.palette-wrapper-inner {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.swatch, .move-space{
  outline: 2px solid rgba(0,0,0,0);
  width: 2em;
  height: 2em;
  margin: 0.5em;
}
.swatch {
  outline: 2px solid #fff;
  box-sizing: border-box;
  width: 2em;
  height: 2em;
  -webkit-box-shadow: 0 0 4px 4px #000;
  -moz-box-shadow: 0 0 4px 4px #000;
  box-shadow: 0 0 4px 4px #000;
  -webkit-appearance: none;
  border-radius: 1px;
}
.swatch.selected {
  -webkit-box-shadow: 0 0 6px 4px #bbb;
  -moz-box-shadow: 0 0 6px 4px #bbb;
  box-shadow: 0 0 6px 4px #bbb;
}
.swatch.moving {
  display: none;
}
.swatch.alpha {
  background-image: url("../assets/images/alpha.png");
}
.floating-swatch {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
}

.palette-svg{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  pointer-events: none;
}