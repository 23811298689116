.new-group-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 3em;
    width: 200px;
}
.new-group-label{
    display: block;
    margin: 0.7em 0;
    text-align: left;
    width: 200px;
}
.new-group-input-wrapper{
    width:100%;
    text-align: left;
}
.new-group-input{
    width: 70px;
}
.new-group-input-name{
    width: 200px;
}
.new-group-bottom{
    width:200px;
    text-align: right;
    margin: 1em 0;
}
.new-group-error{
    color:crimson;
    margin:1em 0;
}
.new-group-select{
    width:200px;
    padding: 0.3em 0;
}

.new-group-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin:  0.7em 0;
  }
.new-group-swatch{
    background-color: red;
    width: 1.55em;
    height: 1.55em;
}
.new-group-heading{
    font-size:1.8em;
}