

.hotkeys{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    padding:1em 2em;
    width: 100%;
    max-width: 296px;
    min-width: 241px;
    border-collapse: collapse;
    background-color: #fff;
    color: #000;
}
.hotkeys-table{
    display:grid;
    table-layout: fixed;
}

.hotkeys-table thead th {
    background-color: #808080;
    color: #fff;
}
  
.hotkeys-table th, .hotkeys-table td {
    border: 1px solid #ccc;
    padding: 8px 12px;
    text-align: left;
    min-width: 90px;
}

.hotkeys-table th {
    background-color: #f5f5f5;
    color: #333;
}
.hotkeys-table th:nth-child(1), .hotkeys-table td:nth-child(1) {
    width: 20%;
}

.hotkeys-table th:nth-child(2), .hotkeys-table td:nth-child(2) {
    width: 80%;  /* Example width for the 'Description' column */
}

.hotkeys-table tbody tr:nth-child(even) {
    background-color: #ddd;
}

.hotkeys-table tbody tr:hover {
    background-color: #e8f4ff;
}
  