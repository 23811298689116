.save-palette-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 3em;
    width: 200px
}
.save-palette-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
  }
.save-palette-swatch{
    background-color: red;
    width: 1.55em;
    height: 1.55em;
}
.save-palette-label{
    display: block;
    margin: 0.7em 0 0.3em;
    text-align: left;
    width: 200px;
}
.save-palette-input{
    width: 78px;
}
.save-palette-input-name{
    width: 200px;
}
.save-palette-bottom{
    width:200px;
    text-align: right;
    margin: 1em 0;
}
.save-palette-error{
    color:crimson;
    margin:1em 0;
}
.sign-in-button{
    color:limegreen;
}