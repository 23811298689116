.resize-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 3em;
    width: 200px;
}
.resize-label{
    display: block;
    margin: 0.7em 0;
    text-align: left;
    width: 200px;
}
.resize-input-wrapper{
    width:100%;
    text-align: left;
}
.resize-input{
    width: 70px;
}
.resize-bottom{
    width:200px;
    text-align: right;
    margin: 1em 0;
}
.resize-error{
    color:crimson;
    margin:1em 0;
}
.resize-select{
    width:200px;
    padding: 0.3em 0;
}
.resize-heading{
    font-size:1.8em;
}

.ratio-label{
    width: 100%;
    margin: 1em 0;
    text-align: left;
}