.new-document-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 3em;
    width: 200px;
}
.new-document-label{
    display: block;
    margin: 0.7em 0;
    text-align: left;
    width: 200px;
}
.new-document-input-wrapper{
    width:100%;
    text-align: left;
}
.new-document-input{
    width: 70px;
}
.new-document-input-name{
    width: 200px;
}
.new-document-bottom{
    width:200px;
    text-align: right;
    margin: 1em 0;
}
.new-document-error{
    color:crimson;
    margin:1em 0;
}
.new-document-select{
    width:200px;
    margin-top:1em;
    padding: 0.3em 0;
}

.new-document-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin:  0.7em 0;
  }
.new-document-swatch{
    background-color: red;
    width: 1.55em;
    height: 1.55em;
}
.new-document-heading{
    font-size:1.8em;
}
.new-document-right{
    width:100%;
    text-align: right;
    margin-bottom: 0.5em;
}
.new-document-button{
    padding:0.25em 0.5em;
    background-color: #fff;
    color:#000;
    border-radius: 0.25em;
    margin-left: 0.5em;
}