.sign-in-wrapper{
    width:100%;
    max-width: 280px;
    background-color: #fff;
    color:#000;
    border-radius: 1em;
    padding:1em;
    text-align: center;
}
.register-button, .sign-in-button{
    color:limegreen;
}
.sign-in-label, .sign-in-input, .sign-in-bottom{
    display: block;
    width:100%;
    margin: 0.7em 0;
    box-sizing: border-box;
}
.sign-in-form{
    width:100%;
    margin:auto;
    text-align: left;
}
.sign-in-right{
    text-align: right;
}
.sign-in-submits{
    background-color: #2a2a2a;
    border-radius: 0.5em;
    padding: 0.5;
}
.sign-in-error{
    margin: 0.7em 0;
    width: 100%;
    color: crimson;
}
.sign-in-success{
    margin: 0.7em 0;
    width: 100%;
    color: limegreen;
}
.sign-in-reset-password{
    color: crimson;
}
.sign-in-consent{
    margin: 0.7em 0;
}