.open-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 2em;
    width: 264px;
}
.open-label{
    display: block;
    margin: 0 0 0.7em;
    text-align: left;
    width: 264px;
    font-weight: bold;
}
.open-input-wrapper{
    margin: 0 0 0.7em;
}
.open-input-name{
    width: 264px;
    margin: 0 0 0.7em;
}
.open-bottom{
    width:264px;
    text-align: right;
    margin: 1em 0;
}
.open-error{
    color:crimson;
    margin:1em 0;
}
.open-select{
    width:264px;
    padding: 0.3em 0;
    margin: 0 0 0.7em;
}
.open-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin: -0.2em 0.5em 0.7em;
}
.open-swatch{
    background-color: red;
    width: 1.4em;
    height: 1.4em;
    margin:0.2em;
    border: 2px solid #fff;
}
.open-swatch.included{
    border-color: limegreen;
}
.open-browse{
    background-color: #555;
    border-radius:0.5em;
    padding:0.5em;
    margin:2em 0;
}
.open-canvas{
    width:100%;
    height:auto;
    margin: 0 0 0.7em;
}
.open-submit{
    margin-left: 0.5em;
}
.open-loading-wrapper{
    padding:1em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.open-input-wrapper{
    width:264px;
    text-align: left;
}
.open-size{
    width: 70px;
}
.open-right{
    width:100%;
    text-align: right;
    margin-bottom: 0.5em;
}
.open-button{
    padding:0.25em 0.5em;
    background-color: #fff;
    color:#000;
    border-radius: 0.25em;
    margin-left: 0.5em;
}
.sample-count{
    width: 35px;
    font-weight: normal;
}
.sample-count-range{
    width: 100%;
    margin: 0 0 0.7em;
}