.profile-wrapper{
    width: 100%;
    max-width: 280px;
    background-color: #fff;
    color:#000;
    border-radius: 1em;
    padding:1em;
    text-align: center;
}
.profile-button{
    color:limegreen;
}
.profile-label, .profile-input, .profile-bottom{
    display: block;
    width:100%;
    margin: 0.7em 0;
    box-sizing: border-box;
}
.profile-form{
    width:100%;
    margin:auto;
    text-align: left;
}
.profile-right{
    text-align: right;
}
.profile-error{
    margin: 0.7em 0;
    width: 100%;
    color: crimson;
}
.profile-success{
    margin: 0.7em 0;
    width: 100%;
    color: limegreen;
}
.profile-reset-password{
    color: crimson;
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
}
.profile-submit{
    margin: 0.3em;
}