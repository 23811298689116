.export-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    border-radius: 1em;
    padding:1em 3em;
    width: 200px;
}
.export-heading{
    font-size:1.4em;
}
.export-label{
    display: block;
    margin: 0.7em 0;
    text-align: left;
    width: 200px;
}
.export-integer{
    display:inline-block;
    width: 3em;
}
.export-select{
    width: 200px;
    padding:0.3em 0;
}
.export-input-name{
    width: 200px;
}
.export-bottom{
    width:200px;
    text-align: right;
    margin: 1em 0;
}
.export-error{
    color:crimson;
    margin:1em 0;
}
.sign-in-button{
    color:limegreen;
}

.background-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    overflow: auto;
    margin: 1em 0;
}
.background-swatch{
    background-color: red;
    width: 1.3em;
    height: 1.3em;
    border: 2px solid #fff;
}