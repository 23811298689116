.home-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:0 32px;
    background-color: #333;
    color: #fff;
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.home{
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
}
.header-nav-mobile{
    display: none;
    flex-direction: row;
    align-items: center;
    padding: 24px;
}
.home-link{
    color: #fff !important;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
}
.home-logo{
    width:43px;
    height:43px;
    padding-right: 8px;
}
.home-link-title{
    font-size:20px;
    margin:0;
    padding: 0;
}
.home-header-spacer{
    flex-grow: 6;
}
.home-header-button{
    flex-grow:1;
    text-align: center;
    color:#BCBCBC !important;
    text-decoration: none;
    cursor: pointer;
    padding: 0 8px;
}
.home-header-line{
    flex-grow:0.5;
    text-align: center;
    color: limegreen;
}
.home-header-button.sign-in{
    color:#fff !important;
}
.get-started-button{
    background-color: #aaFFaa;
    color:#000 !important;
    text-decoration: none;
    border-radius: 13px;
    padding: 8px 16px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.home-menu-button{
    cursor: pointer;
    padding:10px 4px;
}


.gradient{
    width: 30%;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    border-radius: 366px;
    background: rgba(77, 184, 72, 0.4);
    filter: blur(10vw);
    position: absolute;
}
.gradient.one{
    right: -12vw;
    top:70px;
}
.gradient.two{
    left: -12vw;
    top:550px;
}
.gradient.three{
    left: -12vw;
    top:2300px;
}
.gradient.four{
    right: -12vw;
    top:2850px;
}

.description{
    display: flex;
    flex-direction: row;
    align-items: center;
    height:500px;
}
.description-left{
    width: 45vw;
}
.description-heading{
    font-size: 5vw;
}
.ease{
    position: relative;
    color: #4DB848;
}
.ease-underline{
    position: absolute;
    right: 0;
    bottom: -24px;
    width: 100%;
}
.description-content{
    color: #BCBCBC;
    font-size:1.8vw;
    margin: 4vw 0 2vw 0;
}
.description-image-wrapper{
    width: 35%;
    min-width: 300px;
    position: relative;
}
.description-image{
    width:100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 25%;
    flex-shrink: 0;
}
.description-bucket{
    position: absolute;
    width: 8%;
    height: 8%;
    padding: 2%;
    top: -16px;
    right:25%;
    background-color: #4DB848;
    border-radius: 25%;
}
.description-swatches{
    position: absolute;
    background-color: #fff;
    border-radius: 10%;
    width: 15%;
    padding: 2%;
    left: -24px;
    bottom: 15%;
}
.description-slider{
    position: absolute;
    width: 22.5%;
    right: 40px;
    bottom: -16px;
}
.description-bottom{
    display: flex;
    flex-direction: row;
}
.description-get-started, .description-guide{
    border-radius: 1vw;
    font-size: 1.5vw;
    padding: 0.5em 2em;
    margin-right: 1em;
    cursor: pointer;
    text-decoration: none;
}
.description-get-started{
    color:#000 !important;
    background: #4DB848;
}
.description-guide{
    color:#fff !important;
    outline: solid 1px #fff;
    background-color: transparent;
}

.create-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    height:275px;
}
.create-account-heading{
    font-weight: normal;
    padding: 0;
    margin: 0;
    font-size: 4vw;
    width: 25vw;
}
.create-account-text{
    color: #BCBCBC;
    font-size: 1.5vw;
    width:22vw;
}
.create-account-line{
    text-align: center;
}
.create-account-button{
    border-radius: 1vw;
    font-size: 1.5vw;
    padding: 0.5em 2em;
    cursor: pointer;
    color:#000;
    background: #4DB848;
}

.features{
    background: #2F2F2F;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;
}
.features-heading{
    padding: 0;
    margin: 0;
}
.features-text{
    color: #BCBCBC;
    max-width:360px;
    font-size: 16px;
    text-align: center;
    margin: 1em;
}
.pressure, .transformation, .layers, .export{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 350px;
}
.features-content{
    position: relative;
    padding-right: 10%;
    font-size: 14px;
    max-width: 500px;
}
.features-number{
    padding:8px;
    width:32px;
    height: 32px;
    border-radius: 50%;
    background-color: #4DB848;
    color: #000;
    font-weight: bold;
    font-size:24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}
.features-subheading{
    max-width: 350px;
}

.features-image{
    width: 30%;
    min-width: 250px;
    aspect-ratio: 1/1;
    border-radius: 20%;
    object-fit: cover;
}
.features-image-mobile{
    display: none;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 20%;
    object-fit: cover;
    margin:32px 0;
}

.about{
    height: 420px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.about-content{
    display: flex;
    flex-direction: column;
    flex-grow:1;
}
.about-avatar{
    width: 240px;
    height: auto;
    border-radius: 50px;
    object-fit: cover;
    flex-shrink: 0;
    margin-left:17%;
}
.about-avatar-square{
    display: none;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50px;
    flex-shrink: 0;
}

.home-guide{
    height:160px;
    margin: 60px 0 250px 0;
    font-size:14px;
    background: #2F2F2F;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px 64px;
}
.home-guide-left{
    flex-grow:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.home-guide-button{
    border-radius: 10px;
    font-size: 19px;
    padding: 0.5em 2em;
    cursor: pointer;
    color:#000 !important;
    text-decoration: none;
    background: #4DB848;
}

.footer-wrapper{
    position: absolute;
    left:-1000px;
    right: -1000px;
    bottom: 0;
    background: #242424;
    height:150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-nav{
    width: 90vw;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer-left, .footer-right{
    display: flex;
    flex-grow:1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer-div{
    color:#BCBCBC !important;
    flex-grow: 1;
    text-align: center;
}
.footer-spacer{
    flex-grow:4;
}

@media screen and (min-width: 1280px) {
    .description-left{
        width: 576px;
    }
    .description-heading{
        font-size: 64px;
    }
    .description-content{
        font-size: 23px;
        margin: 51.2px 0 25.6px 0;
    }
    .description-get-started, .description-guide{
        font-size: 19.2px;
        border-radius: 12px;
    }

    .create-account-heading{
        font-size: 51.2px;
        width: 320px;
    }
    .create-account-text{
        font-size: 19.2px;
        width: 281.6px;
    }
    .create-account-button{
        border-radius: 12.8px;
        font-size: 19.2px;
    }

    .about-avatar{
        width: 240px;
        height: auto;
        border-radius: 50px;
        object-fit: cover;
        flex-shrink: 0;
    }
}


@media screen and (max-width: 768px) {
    .gradient{
        width:75%;
        background: rgba(77, 184, 72, 0.3);
        filter: blur(20vw);
    }
    .gradient.one{
        top: -32px;
        right: -100px;
    }
    .gradient.two{
        top: 700px;
        left: -100px;
    }
    .gradient.three, .gradient.four{
        display: none;
    }

    .home-wrapper{
        padding:0;
    }
    .header-nav, .description, .about, .home-guide{
        padding: 0 24px;
    }

    .header-nav{
        display: none;
    }
    .header-nav-mobile{
        display: flex;
    }

    .description{
        flex-direction: column;
        height: fit-content;
    }
    .description-left{
        width: 100%;
    }
    .description-heading{
        font-size: 32px;
    }
    .description-content{
        font-size: 16px;
        margin: 24px 0;
    }
    .description-get-started, .description-guide{
        border-radius: 12px;
        font-size: 16px;
        padding: 0.5em 1em;
    }
    .description-image-wrapper{
        width: 80%;
        position: relative;
        margin: 32px;
    }
    .description-image{
        width:100%;
    }

    .create-account{
        padding:24px;
        flex-direction: column;
        height: fit-content;
    }
    .create-account-heading{
        font-size:24px;
        width: fit-content;
    }
    .create-account-text{
        font-size:16px;
        width: fit-content;
    }
    .create-account-line{
        display: none;
    }
    .create-account-button{
        border-radius: 12px;
        font-size: 19px;
        padding: 0.5em 2em;
        margin: 16px;
    }
    
    .pressure, .transformation, .layers, .export{
        flex-direction: column;
        height: fit-content;
    }

    
    .features{
        padding: 32px;
    }
    .features-image{
        display:none;
    }
    .features-image-mobile{
        display: block;
    }
    

    .about{
        flex-direction: column;
        height: fit-content;
        margin: 32px 0;
    }
    .about-content{
        flex-grow:0;
    }
    .about-avatar{
        display: none;
    }
    .about-avatar-square{
        display: block;
    }


    .home-guide{
        flex-direction: column;
        height:200px;
        margin: 50px 0 200px 0;
        background: transparent
    }
    .home-guide-heading{
        text-align: center;
    }
    .home-guide-right{
        flex-grow:1;
    }


    .footer-nav{
        flex-direction: column;
    }
    .footer-spacer{
        display: none;
    }
    .footer-left,.footer-right{
        width:100%;
    }
}

.mobile-menu-wrapper{
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
}
.mobile-menu{
    position: relative;
    width:100%;
    height: 100%;
    box-sizing: border-box;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobile-menu-close-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.mobile-menu-section{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mobile-menu-button{
    display:flex;
    flex-direction: column;
    justify-content: center;
    flex-grow:1;
    color:#BCBCBC !important;
    text-decoration: none;
}
.mobile-menu-section.line{
    color:#4DB848
}
.mobile-menu-logo{
    width:26px;
    height:26px;
    padding-right: 4px;
}
.mobile-menu-title{
    font-size:16px;
    margin:0;
    padding: 0;
}
.mobile-menu-gradient{
    width: 170px;
    height: 170px;
    flex-shrink: 0;
    border-radius: 366px;
    background: rgba(77, 184, 72, 0.4);
    filter: blur(80px);
    position: absolute;
}
.mobile-menu-gradient.one{
    right: -12vw;
    top: -50px;
}
.mobile-menu-gradient.two{
    left: -12vw;
    bottom: -50px;
}