.header {
  flex: 0 0 auto;
  background-color: #484848;
  display: flex;
  align-items: center;
  padding: 0 0.25em;
  overflow:auto;
}
.header::-webkit-scrollbar {
  display: none;
}

.dropdown-menu{
  position:fixed !important;
}
.header-item .dropdown-menu,
.header-button {
  padding: 0.25em;
  flex: 0 0 auto;
  background-color: #484848;
  width:auto;
}
.header-item .dropdown-item {
  padding: 0.25em 0.5em 0.25em 0;
  background-color: #484848;
  color: #fff;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: fit-content;
}
.header-item .dropdown-item:hover {
  background-color: #808080;
}
.header-button {
  color: #aaa;
}
.header-button:hover {
  color: #fff;
}
.header-item:hover {
  text-decoration: underline;
}
.droppdow-space{
  min-width:1.5em;
}
.droppdown-checked{
  width:1em;
  text-align: center;
}
.header-logo{
  width:24px;
  height:24px;
  margin:0.25em;
  flex: 0 0 auto;
}
.color-wrapper{
  text-align: right;
}
.color-okay{
  background-color: #fff;
  padding:0.3em;
  border-radius: 0.5em;
  margin-top:0.6em;
  color:#000;
}