@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?5tnmvc");
  src: url("../assets/fonts/icomoon.eot?5tnmvc#iefix") format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?5tnmvc") format("truetype"),
    url("../assets/fonts/icomoon.woff?5tnmvc") format("woff"),
    url("../assets/fonts/icomoon.svg?5tnmvc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-import:before {
  content: "\e90d";
}
.icon-export:before {
  content: "\e90e";
}
.icon-magnet:before {
  content: "\e9ab";
}
.icon-back:before {
  content: "\ea38";
}
.icon-book:before {
  content: "\e91f";
}
.icon-box:before {
  content: "\eaae";
}
.icon-folder:before {
  content: "\e92f";
}
.icon-transform:before {
  content: "\e900";
}
.icon-x:before {
  content: "\e901";
}
.icon-hand-paper-o:before {
  content: "\e902";
}
.icon-swap:before {
  content: "\e903";
  font-size: 1.25em;
}
.icon-floppy:before {
  content: "\e904";
}
.icon-dots:before {
  content: "\e905";
}
.icon-patreon:before {
  content: "\e906";
}
.icon-discord:before {
  content: "\e907";
}
.icon-dash-circle:before {
  content: "\e908";
}
.icon-bucket:before {
  content: "\e909";
}
.icon-dash-square:before {
  content: "\e90a";
}
.icon-pencil6:before {
  content: "\e913";
}
.icon-eraser:before {
  content: "\e915";
}
.icon-file-picture:before {
  content: "\e927";
}
.icon-palette:before {
  content: "\e92a";
}
.icon-gradient:before {
  content: "\e930";
}
.icon-eyedropper:before {
  content: "\e931";
}
.icon-image5:before {
  content: "\e942";
}
.icon-film4:before {
  content: "\e961";
}
.icon-chess:before {
  content: "\e978";
}
.icon-spinner:before {
  content: "\e97a";
}
.icon-search:before {
  content: "\e986";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-download2:before {
  content: "\e9c5";
}
.icon-stack2:before {
  content: "\e9ce";
}
.icon-eye:before {
  content: "\e9cf";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-compass3:before {
  content: "\ea41";
}
.icon-undo:before {
  content: "\eaa5";
}
.icon-redo:before {
  content: "\eaa6";
}
.icon-spinner6:before {
  content: "\eb27";
}
.icon-zoom-in:before {
  content: "\eb31";
}
.icon-zoom-out:before {
  content: "\eb32";
}
.icon-enlarge:before {
  content: "\eb3c";
}
.icon-enlarge2:before {
  content: "\eb3e";
}
.icon-shrink2:before {
  content: "\eb3f";
}
.icon-wrench:before {
  content: "\eb58";
}
.icon-cog:before {
  content: "\eb5f";
}
.icon-magic-wand:before {
  content: "\eb6d";
}
.icon-grid7:before {
  content: "\ec63";
}
.icon-move:before {
  content: "\ed4d";
}
.icon-minus3:before {
  content: "\ed5e";
}
.icon-play4:before {
  content: "\ed85";
}
.icon-pause2:before {
  content: "\ed86";
}
.icon-first:before {
  content: "\ed8a";
}
.icon-last:before {
  content: "\ed8b";
}
.icon-previous2:before {
  content: "\ed8c";
}
.icon-next2:before {
  content: "\ed8d";
}
.icon-arrow-right5:before {
  content: "\edc8";
}
.icon-arrow-down5:before {
  content: "\edc9";
}
.icon-checkbox-unchecked2:before {
  content: "\ee6f";
}
.icon-radio-unchecked:before {
  content: "\ee73";
}
.icon-rulers:before {
  content: "\ee81";
}
.icon-font-size:before {
  content: "\ee91";
}
.icon-typography:before {
  content: "\ee92";
}
.icon-bold:before {
  content: "\ee97";
}
.icon-italic:before {
  content: "\ee99";
}
.icon-pilcrow:before {
  content: "\eeb1";
}
.icon-paragraph-left:before {
  content: "\eeb7";
}
.icon-paragraph-left2:before {
  content: "\eebb";
}
.icon-paragraph-center2:before {
  content: "\eebc";
}
.icon-paragraph-right2:before {
  content: "\eebd";
}
.icon-terminal:before {
  content: "\eed4";
}
.icon-keyboard:before {
  content: "\e955";
}
.icon-pencil:before {
  content: "\e90b";
}
.icon-line:before {
  content: "\e90c";
}
.icon-guide:before {
  content: "\e9b3";
}