.load-palette-wrapper{
    display:flex;
    flex-direction: column;
    background-color: #181818;
    border-radius: 1em;
    padding:1em;
    width: 300px;
}
.load-palette-heading{
    width: 100%;
    text-align: center;
}
.load-palette-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin: 0.5em 0;
  }
.load-palette-swatch{
    background-color: red;
    width: 1.68em;
    height: 1.68em;
    margin:0.2em;
    border: 2px solid #fff;
}
.load-palette-swatch.included{
    border-color: limegreen;
}
.load-palette-select{
    width:100%;
    padding: 0.3em 0;
    margin-top: 1em;
}
.load-palette-bottom{
    width:100%;
    text-align: right;
    margin: 1em 0;
}
.load-palette-right{
    width:100%;
    text-align: right;
    margin-bottom: 0.5em;
}
.load-palette-button{
    padding:0.25em 0.5em;
    background-color: #fff;
    color:#000;
    border-radius: 0.25em;
    margin-left: 0.5em;
}
.load-palette-error{
    color:crimson;
    margin:1em 0;
}
.sign-in-button{
    color:limegreen;
}
.load-palette-label{
    text-align: left;
    width:100%;
    font-weight: bold;
}
.palette-loading-wrapper{
    padding:1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}