.dashboard-wrapper{
    display: flex;
    flex-direction: row;
    max-height: 100%;
    overflow: hidden;
    position: relative;
}
.dashboard-wrapper, .dashboard-wrapper * {
    outline: none;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -webkit-touch-callout: none;
}
.sidebar{
    width:310px;
    height: 100%;
    padding:2em;
    display:flex;
    flex-direction: column;
    justify-content: left;
    background-color: #181818;
    box-sizing: border-box;
    overflow-y:auto;
}
.menu-button{
    position:fixed;
    top:1em;
    left:1em;
    display:none;
    width:2.5em;
    height:2.5em;
    background-color: #404040;
    border-radius: 50%;
    z-index:1000;
}
.sidebar-heading-wrapper{
    margin: 0 0 0.5em;
    cursor:pointer;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #fff !important;
    text-decoration: none;
}
.sidebar-heading{
    display: inline-block;
    margin: 0;
    padding: 0 0.5em;
    font-size: 2em;
}
.sidebar-subheading{
    display: inline-block;
    margin: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
}
.sidebar-button{
    text-align: left;
    padding: 0.5em;
    margin:0;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#fff !important;
    text-decoration: none;
}
.sidebar-button:hover{
    background-color: #404040;
}
.sidebar-button.menu{
    display: none;
}
.sidebar-button-icon{
    padding-right: 0.5em;
    font-size: 1.25em;
}
@media screen and (max-width: 768px) {
    .menu-button{
        display:block;

    }
    .sidebar-wrapper {
        position:fixed;
        width:100%;
        height:100%;
        z-index:1000;
    }
    .sidebar-wrapper.hidden {
        display: none;
    }
    .sidebar-button.menu{
        display: block;
    }
}
.display-name{
    font-weight:bold;
}
.user-settings{
    display:flex;
    flex-direction: column;
    justify-content: left;
}
.user-button{
    background-color: #404040;
    padding:0.5em;
    margin: 0.2em 1em 0;
    text-align: left;
}

.documents{
    display: flex;
    flex-direction: column;
    text-align: center;
    max-height: 100%;
    height: 100%;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.documents-heading{
    margin: 1em 0;
}
.documents-list{
    height: fit-content;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    gap:0.5em;
    padding:1em;
}

.documents-list:after {
    content: '';
    flex: auto;
} 

.document-item{
    position: relative;
    width:130px;
    height:130px;
    background-color: #181818;
    border-radius: 1em;
    padding:0.5em;
    text-align: center;
    cursor: pointer;
}
.document-item.extra{
  visibility: hidden;
  height: 0;
  padding: 0 0.5em;
}
.group-icon{
    font-size: 2em;
}
.document-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.document-image-wrapper{
    height:72px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.document-image{
    max-width: 100%;
    max-height: 100%; 
    image-rendering: crisp-edges;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
}
.document-title{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5em;
}
.document-settings{
    font-size:1.25em;
}
.document-plus{
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    color:#888;
}
.star-button{
    color:#444;
    padding:0 .5em;
    display: inline-block;
}
.star-button.starred{
    color:#fff;
}
.document-time{
    width:100%;
    text-align: left;
    font-size:0.7em;
}
.logo{
    width:32px;
    height:auto;
}

.document-item.floating{
    position: absolute;
    z-index: 1001;
    border: 1px solid #aaa;
    opacity:0.5;
}
.document-item.floating, .document-item.floating *{
    pointer-events: none;
}
.context-menu{
    position: absolute;
    z-index: 1002;
    width:100px;
    height:124px;
    background-color:#181818;
    border: 1px solid #aaa;
    display: flex;
    flex-direction: column;
}
.document-item.hidden{
    visibility: hidden;
}
.document-item.transparent{
    opacity: 0.5;
}

.copywrite {
    width:100%;
    text-align: center;
    font-weight: bold;
    padding: 0.5em 0;
}

.loading-wrapper.opaque{
    background-color: rgba(0,0,0,0.5);
}

.group-heading-wrapper-outer{
    display: flex;
    flex-direction: row;
    margin: 1em;
    align-items: center;
    justify-content: center;
}
.group-heading-wrapper-inner{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.group-heading{
    cursor: pointer;
    width: fit-content;
}
.group-rename{
    padding: 0 1em;
}
.group-back{
    cursor: pointer;
    font-size: 1.5em;
}