.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
}
.bg,
.layer,
.cursor-layer{
  position: absolute;
  transform-origin: left top;
  left: 50%;
  top: 50%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  outline: none;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-touch-callout: none;
}
.svg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.animated-dash1 {
  animation: dash1 0.5s linear infinite;
}
@keyframes dash1 {
  from {
    stroke-dashoffset: 8;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.animated-dash2 {
  animation: dash2 0.5s linear infinite;
}
@keyframes dash2 {
  from {
    stroke-dashoffset: 12;
  }
  to {
    stroke-dashoffset: 4;
  }
}

.layer.invisible, .svg-overlay.invisible {
  display: none;
}
.bg {
  background-image: url("../assets/images/bg.png");
}

.details{
  background-color: #ccc;
  color: #000;
  padding:0.5em;
  font-size: 0.65em;
  position:absolute;
  bottom:0;
  right:0;
}

.layer-context-menu{
  position: absolute;
  background-color: #444;
  color:#fff;
  padding: 0.3em;
  cursor:pointer;
  border-radius: 0.3em;
}