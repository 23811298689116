.reset-password-wrapper{
    text-align: center;
    padding: 1em;
}
.reset-password-form{
    width:256px;
    margin: 0 auto;
}
.password-heading{
    margin:0;
}
.password-input{
    width:100%;
    margin: 0.5em 0;
}
.input-right{
    text-align: right;
    margin:1em 0;
}
.error-message{
    color: crimson;
}