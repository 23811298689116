.guide-wrapper{
    display: flex;
    flex-direction: row;
    max-height: 100%;
}
.guide-sidebar{
    width:310px;
    height: 100%;
    padding:2em;
    display:flex;
    flex-direction: column;
    justify-content: left;
    background-color: #181818;
    box-sizing: border-box;
    overflow-y:auto;
}
.menu-button{
    position:fixed;
    top:1em;
    left:1em;
    display:none;
    width:2.5em;
    height:2.5em;
    background-color: #404040;
    border-radius: 50%;
}
.guide-sidebar-heading-wrapper{
    margin: 0 0 0.5em;
    cursor:pointer;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    color:#fff !important;
    text-decoration: none;
}
.guide-sidebar-heading{
    display: inline-block;
    margin: 0;
    padding: 0 0.5em;
    font-size: 2em;
}
.guide-sidebar-subheading{
    display: inline-block;
    margin: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
}
.guide-sidebar-button{
    text-align: left;
    padding: 0.5em;
    margin:0;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#fff !important;
    text-decoration: none;
}
.guide-sidebar-button:hover{
    background-color: #404040;
}
.guide-sidebar-button.menu{
    display: none;
}
.guide-sidebar-button-icon{
    padding-right: 0.5em;
    font-size: 1.25em;
}
@media screen and (max-width: 768px) {
    .menu-button{
        display:block;

    }
    .guide-sidebar-wrapper {
        position:fixed;
        width:100%;
        height:100%;
    }
    .guide-sidebar-wrapper.hidden {
        display: none;
    }
    .guide-sidebar-button.menu{
        display: block;
    }
}

.guide{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2em;
    box-sizing: border-box;
    position:relative;
    z-index:1;
}


.guide-title{
    text-align: center;
    font-weight: bold;
}

.guide-heading{
    font-weight: bold;
}

h2.guide-heading{
    margin: 1em 0 0.5em 0;
}

.guide-section{
    font-weight: bold;
    margin: 0.3em 0;
    color:#fff !important;
    text-decoration: none;
}
.guide-item{
    margin: 0.3em 0 0.3em 0.75em;
    margin-left: 0.7em;
    color:#fff !important;
    text-decoration: none;
}

.underline{
    text-decoration: underline;
}

.hotkeys-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:2em;
}

.guide::-webkit-scrollbar,
.guide-sidebar::-webkit-scrollbar {
    -webkit-appearance: none;
}
.guide::-webkit-scrollbar:vertical,
.guide-sidebar::-webkit-scrollbar:vertical {
    width: 24px;
}
.guide::-webkit-scrollbar-thumb,
.guide-sidebar::-webkit-scrollbar-thumb {
    background-color: #505050;
}
.guide::-webkit-scrollbar-track,
.guide-sidebar::-webkit-scrollbar-track {
    background-color: #2a2a2a;
}

.guide-wrapper .indent{
  margin-left: 0.5em;
}
.guide-wrapper p.indent{
  margin-left: 1.5em;
}

.video-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}