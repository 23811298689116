.window-header {
  background-color: #484848;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 0.4em;
  border-radius: 0.5em 0.5em 0 0;
  overflow: hidden;
}
.window-select {
  background: #333;
  color: #fff;
  border-radius: 0.5em;
  padding: 0.25em;
}
.window-content {
  position: relative;
  background-color: #333;
  flex: 1 1;
  overflow: hidden;
}
.viewport-button, .palette-button{
  padding: 0.25em 0.5em;
  color: #aaa;
}
.viewport-button:hover, .palette-button:hover {
  color: #fff;
}