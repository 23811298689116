.tools {
  flex: 0 0 auto;
  width: 3em;
  border-radius: 0 0.5em 0 0;
  background-color: #484848;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  margin-top: 0.5em;
  padding-top: 0.5em;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.tools::-webkit-scrollbar {
  display: none;
}
.tools {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tool-button, .color-swap {
  flex: 0 0 auto;
  font-size: 1.2em;
  padding: 0.15em;
  transition: all 0.1s;
}
.undo-redo-button{
  flex: 0 0 auto;
  font-size: 1.2em;
  transition: all 0.1s;
  padding: 0.15em;
}
.tool-button:hover{
  font-size: 1.5em;
  padding: 0;
}
.modify-button{
  flex: 0 0 auto;
  border-radius: 50%;
  font-size: 0.6em;
  width: 2.5em;
  height: 2.5em;
  text-align: center;
  border: 1px solid #fff !important;
  margin: 0.7em;
}
.modify-button.selected{
  color: #000;
  background-color: #fff;
}
.tool-button.selected {
  color: limegreen;
}
.selected-colors{
  height:30px;
  width:30px;
  flex: 0 0 auto;
  display: inline-block;
  position: relative;
}
.secondary-color {
  position:absolute;
  right: 0;
  bottom: 0;
  border: 2px solid #fff;
  width: 1em;
  height: 1em;
  box-shadow: 0 0 4px 2px #000;
  background-size: 1em 1em;
}
.selected-color {
  position:absolute;
  top: 0;
  left: 0;
  border: 2px solid #fff;
  width: 1em;
  height: 1em;
  box-shadow: 0 0 4px 2px #000;
  background-size: 1em 1em;
}
.tooltip{
  position: fixed;
}
.modify-wrapper{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}