.layers-wrapper-outer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.layers-wrapper-inner {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 52px;
  position: relative;
}
.layer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.5em;
  border-bottom: 2px solid #111;
  background-color: #404040;
  cursor: pointer;
  font-size: 0.9em;
}
.layer-wrapper.includes {
  background-color: #606060;
}
.layer-wrapper.selected {
  border: 2px solid limegreen;
}
.layer-wrapper.move-above {
  border-top: 4px solid limegreen;
}
.layer-wrapper.move-below {
  border-bottom: 4px solid limegreen;
}
.preview, .guide-icon{
  outline: 2px solid #fff;
  background-image: url("../assets/images/bg.png");
  background-size: 8px;
  width: 30%;
  max-width:48px;
  height: auto;
}
.layer-name {
  margin-left: 0.5em;
  width: 100%;
  max-width: 100%;
  background: none;
  border: none;
  color: inherit;
  padding:0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.layers-bottom {
  position:absolute;
  bottom:0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: right;
  background-color: #404040;
}
.merge-layer,
.add-layer,
.delete-layer {
  font-size: 1.5em;
  padding: 0.33em;
  color: #fff;
}
.add-layer.gray{
  color: #888;
}
.delete-layer.grayed {
  color: #888;
}

.add-layer #dropdown-item-button{
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;

}.add-layer #dropdown-item-button::after {
  content: none; /* This removes the ::after content */
  display: none; /* Optionally, you can also set display to none to ensure it doesn't affect layout */
}
.add-layer .dropdown-menu,
.add-layer .dropdown-item {
  background-color: #484848;
  color: #fff;
  height: fit-content;
}
.add-layer .dropdown-item:hover {
  background-color: #808080;
}

.floating-layer {
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  outline: 2px solid #fff;
  background-image: url("../assets/images/bg.png");
  background-size: 10px;
  opacity: 0.7;
}

.layer-button{
  padding: 0 0.25em;
}

.layer-padding{
  height: 8px;
}
.layers-svg{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  pointer-events: none;
}

.add-layer-menu {
  position: absolute;
  bottom: 68px;
  right: 4px;
  background: #2a2a2a;
  border-radius: 0.5em;
}

.add-layer-menu button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.add-layer-menu button:hover {
  background: #111;
}


.icon-magnet.grayed {
  color: #888;
}