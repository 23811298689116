.change-swatch-wrapper{
    display:flex;
    flex-direction: column;
    background-color: #181818;
    border-radius: 1em;
    padding:1em;
    width: 262px;
}
.change-swatch-heading{
    width: 100%;
    text-align: center;
}
.change-swatch-swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin: 0.5em 0;
  }
.change-swatch-swatch{
    background-color: red;
    width: 1.68em;
    height: 1.68em;
    margin:0.2em;
    border: 2px solid #fff;
}
.change-swatch-swatch.alpha {
    background-image: url("../assets/images/alpha.png");
    background-size: 1.68em 1.68em;
}